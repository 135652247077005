const _ = require('lodash');

const getACLStore = () => {
  const store = {};
  const storeRules = {
    ruleMap: {},
  };
  const refs = {};

  const ACLStore = {
    can(action, target) {
      if (!_.has(store, 'loading')) return true;
      if (_.get(store, 'loading')) {
        throw Error('Can not check ACL while loading');
      }
      if (_.isUndefined(action)) return true;
      if (_.isArray(action)) {
        return _.some(action, (item) => {
          return ACLStore.can(item, target);
        });
      }
      if (_.get(store, ['permissionMap', action])) {
        return true;
      }
      return false;
    },
    async checkAccess(action, target) {
      if (!_.has(store, 'loading')) return true;
      if (_.get(store, 'loading')) {
        throw Error('Can not check ACL while loading');
      }
      if (_.isUndefined(action)) return true;
      if (_.isArray(action)) {
        for (const item of action) {
          const checked = await ACLStore.checkAccess(item, target);
          if (checked) {
            return checked;
          }
        }
      }
      if (_.get(store, ['permissionMap', action])) {
        return true;
      }
      const rules = _.get(storeRules, ['ruleMap', action]);
      if (_.get(rules, 'length')) {
        for (const rule of rules) {
          const checked = await rule(action, target);
          if (checked) {
            return checked;
          }
        }
      }
      return false;
    },
    async is(role, target) {
      return true;
    },
    setPermissions(permissions) {
      const permissionMap = _.keyBy(permissions, 'permission_id');
      _.set(store, 'permissions', permissions);
      _.set(store, 'permissionMap', permissionMap);
    },
    setLoading(loading) {
      _.set(store, 'loading', loading);
    },
    isLoading() {
      return _.get(store, 'loading');
    },
    addRule: (ruleName, value) => {
      _.update(storeRules.ruleMap, ruleName, (currValue) => {
        currValue = currValue || [];
        currValue.push(value);
        return currValue;
      });
    },
    setRef: (key, val) => {
      return _.set(refs, key, val);
    },
    getRef: (key, val) => {
      return _.get(refs, key, val);
    },
    getUserId: () => {
      const authModel = ACLStore.getRef('authModel');
      if (authModel) {
        return authModel.getUserId();
      }
    },
    extends: (definition) => {
      // TODO: check overwrite
      _.merge(ACLStore, definition);
    },
  };
  return ACLStore;
};

const ACL = getACLStore();

exports.ACL = ACL;
