const _ = require('lodash');

const _providers = [];

const _registers = {};

module.exports = {
  get: (path, ...opts) => {
    for (const provider of _providers) {
      return provider.get(path, ...opts);
    }
    // use default vars from process
    return _.get(process.env, path);
  },
  set: (path, ...opts) => {
    for (const provider of _providers) {
      if (_.isFunction(provider.set)) {
        return provider.set(path, ...opts);
      }
    }
    // use default vars from process
    return _.set(process.env, path, ...opts);
  },
  register: (key, value) => {
    _.set(_registers, key, value);
  },
  resolve: (key) => {
    key = _.isFunction(key) ? key() : key;
    return _.get(_registers, key);
  },
  use: (provider) => {
    if (_.indexOf(_providers, provider) === -1) {
      _providers.push(provider);
    }
  },
};
