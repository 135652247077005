const oneOf = require('@vl/mod-utils/oneOf');
const _ = require('lodash');

const modConfig = require('../index');

const toLowerCase = (val) => {
  return `${val}`.toLowerCase();
};

const toGConfig = (val) => {
  return `${val}`.toLowerCase().replace('_', '.');
};

const config = {};
const _localConfig = {};

// TODO: remove
Object.assign(config, process.env, {
  HASURA_GRAPHQL_ENDPOINT:
    process.env.HASURA_GRAPHQL_ENDPOINT ||
    process.env.GATSBY_HASURA_GRAPHQL_ENDPOINT ||
    'https://advisor.unitz.dev/v1/graphql',
  WALLET_GRAPHQL_ENDPOINT:
    process.env.WALLET_GRAPHQL_ENDPOINT ||
    process.env.GATSBY_WALLET_GRAPHQL_ENDPOINT ||
    'https://wallet.unitz.dev/v1/graphql',
});

const configProvider = {
  get: (path) => {
    const rtn =
      oneOf(_localConfig, [path, toLowerCase(path), toGConfig(path)]) ||
      oneOf(config, [path, toLowerCase(path), toGConfig(path)]);

    return rtn;
  },
  set: (config) => {
    _.assign(_localConfig, config);
  },
  register: (...args) => {
    modConfig.register(...args);
  },
  resolve: (...args) => {
    modConfig.resolve(...args);
  },
  loadEnv: () => {
    Object.assign(config, process.env);
  },
};

modConfig.use(configProvider);

module.exports = configProvider;
