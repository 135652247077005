const _ = require('lodash');

const resolveNullish = (...resolvers) => {
  let rtn;
  for (let resolver of resolvers) {
    if (_.isFunction(resolver)) {
      rtn = resolver();
    } else {
      rtn = resolver;
    }
    if (!_.isNil(rtn)) {
      return rtn;
    }
  }
  return rtn;
};

exports.resolveNullish = resolveNullish;
