const _ = require('lodash');

module.exports = (data, options, def) => {
  const arrOpts = _.castArray(options);
  for (const opt of arrOpts) {
    if (_.has(data, opt)) {
      return _.get(data, opt);
    }
  }
  return def;
};
