const reachRouter = require('@reach/router');
const _ = require('lodash');
const URL = require('url');
const querystring = require('querystring');

const getAppOriginMap = _.memoize(() => {
  const appNameMap = {
    user: 'https://unitz.app',
    advisor: 'https://advisor.unitz.app',
    admin: 'https://admin.unitz.app',
  };
  return appNameMap;
});

const getRouteStore = () => {
  const store = {};

  const matchRuleByPath = (urlObject) => {
    for (const ruleName in store) {
      const rule = store[ruleName];
      if (rule && _.isFunction(rule.match) && rule.match(urlObject)) {
        return [ruleName, rule];
      }
    }
    return [];
  };
  const routeStore = {
    addRule: (ruleName, value) => {
      store[ruleName] = value;
    },
    toUrl: (ruleName, params, ...rest) => {
      const rule = _.get(store, ruleName);
      if (rule && _.isFunction(rule.url)) {
        let url = rule.url(params, ...rest);
        if (url && typeof window !== 'undefined') {
          const slug = _.get(window.pageDomain, 'slug');
          if (window.pageDomain && slug) {
            url = `${_.replace(url, `/${slug}`, '/')}`;
          }
        }
        return url;
      }
      throw Error(`Rulename '${ruleName}' is not found`);
      // return '/404';
    },
    parseUrl: (slug) => {
      const urlObject = new URL(slug);
      const { pathname } = urlObject;
      let params = {};
      const found = matchRuleByPath(urlObject);
      const [ruleName, rule] = found;
      if (rule && _.isFunction(rule.parse)) {
        params = rule.parse(urlObject);
      }
      return {
        params,
        ruleName,
        pathname,
        slug,
      };
    },
    getAppOrigin: (appName) => {
      return _.get(getAppOriginMap(), appName, '');
    },
    goBack: () => {
      reachRouter.navigate(-1);
    },
    listen: (callback) => {
      reachRouter.globalHistory.listen((event) => {
        if (_.isFunction(callback)) {
          callback(event);
        }
      });
    },
    queryString: (params, omitFn = (val) => !val) => {
      const queries = _.omitBy(params, omitFn);
      const qs = querystring.stringify(queries);
      return qs ? `?${qs}` : '';
    }
  };
  return routeStore;
};

const routeStore = getRouteStore();

exports.routeStore = routeStore;
