exports.isSSR = () => {
  return typeof window === 'undefined';
};

exports.isWorker = () => {
  return typeof global !== 'undefined' && global.isWorker;
};

exports.subscriptionSSR = () => {
  return {
    subscribe: () => {
      return {
        next() {
          return null;
        },
      };
    },
  };
};
