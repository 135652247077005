const _ = require('lodash');

const ref = {};

ref.flattenGet = (data, path, options = {}) => {
  const rtn = [];
  if (_.isArray(data)) {
    for (let index = 0; index < data.length; index += 1) {
      const levelData = data[index];
      const dataPaths = [levelData, ..._.get(options, 'dataPaths', [])];
      const levelPaths = [index, ..._.get(options, 'levelPaths', [])];
      rtn.push(...ref.flattenGet(levelData, path, { ...options, levelPaths, dataPaths }));
    }
    return rtn;
  }

  const paths = _.toPath(path);
  const level = paths.splice(0, 1);
  if (_.has(data, level)) {
    const levelData = _.get(data, level);
    const dataPaths = [levelData, ..._.get(options, 'dataPaths', [])];
    const levelPaths = [...level, ..._.get(options, 'levelPaths', [])];
    const levelOptions = { ...options, levelPaths, dataPaths };
    if (paths.length) {
      return ref.flattenGet(levelData, paths, levelOptions);
    }
    const mappings = _.get(options, 'mappings');
    let rtn = _.castArray(levelData);
    if (mappings) {
      const get = (path) => {
        const paths = _.toPath(path);
        let index = -1;
        let hasNext = true;
        while (hasNext) {
          if (!paths.length) {
            break;
          }
          index += 1;
          const levelPath = levelPaths[index];
          if (_.isNumber(levelPath)) {
            // eslint-disable-next-line
            continue;
          }
          const [path] = paths.splice(0, 1);
          if (path === levelPath) {
            // eslint-disable-next-line
            continue;
          }
          hasNext = false;
        }
        const levelData = dataPaths[index];
        if (paths.length) {
          return _.get(levelData, paths);
        }
        return levelData;
      };
      rtn = rtn.map((item) => mappings(item, { options: levelOptions, get }));
    }
    return rtn;
  }
  return [];
};

exports.flattenGet = ref.flattenGet;
